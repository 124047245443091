var g = Object.create;
var f = Object.defineProperty;
var h = Object.getOwnPropertyDescriptor;
var i = Object.getOwnPropertyNames;
var j = Object.getPrototypeOf,
  k = Object.prototype.hasOwnProperty;
var m = (b, a) => () => (a || b((a = {
  exports: {}
}).exports, a), a.exports);
var l = (b, a, c, e) => {
  if (a && typeof a == "object" || typeof a == "function") for (let d of i(a)) !k.call(b, d) && d !== c && f(b, d, {
    get: () => a[d],
    enumerable: !(e = h(a, d)) || e.enumerable
  });
  return b;
};
var n = (b, a, c) => (c = b != null ? g(j(b)) : {}, l(a || !b || !b.__esModule ? f(c, "default", {
  value: b,
  enumerable: !0
}) : c, b));
export { m as a, n as b };